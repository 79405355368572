@tailwind base;
@tailwind components;
@tailwind utilities;

iframe#webpack-dev-server-client-overlay {
  display: none;
}

.logo {
  width: 50%;
  height: 50%;
  margin: auto;
}
